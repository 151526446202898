<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="600px"
    :closeOnClickModal="false"
    @open="initData"
    :before-close="closeFn"
    ><el-form :model="form" ref="ruleForm" :rules="rules" label-width="100px">
      <el-form-item label="资源编码：" prop="code">
        <el-input
          v-model.trim="form.code"
          placeholder="请输入资源编码"
          clearable
          maxlength="64"
          show-word-limit
          class="inputWidth"
      /></el-form-item>
      <el-form-item label="资源名称：" prop="name">
        <el-input
          v-model.trim="form.name"
          placeholder="请输入资源名称"
          clearable
          maxlength="64"
          show-word-limit
          class="inputWidth"/></el-form-item
      ><el-form-item label="资源描述：">
        <el-input
          type="textarea"
          v-model.trim="form.description"
          placeholder="请输入资源描述"
          clearable
          maxlength="255"
          :rows="4"
          show-word-limit
          class="inputWidth textArea"/></el-form-item
    ></el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="sureFn">确 定</el-button>
      <el-button @click="closeFn"> 取 消</el-button>
    </span></el-dialog
  >
</template>

<script>
export default {
  name: 'add_apply_source',
  props: {
    showDialog: false,
    isEdit: Boolean,
    sourceData: Object,
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        code: '',
        // parentId: null,

        disabled: 0,
      },
      rules: {
        name: [{ required: true, message: '请输入资源名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入资源编码', trigger: 'blur' }],
      },
      title: '',
    }
  },
  mounted() {},
  methods: {
    initData() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
        if (this.isEdit) {
          this.form.name = this.sourceData.name
          this.form.code = this.sourceData.code
          this.form.description = this.sourceData.description
        } else {
          this.form.name = ''
          this.form.code = ''
          this.form.description = ''
        }
        this.title = this.isEdit ? '编辑应用资源' : '添加应用资源'
      })
    },
    closeFn() {
      this.$emit('closeDialog')
    },
    sureFn() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('sure', this.form)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
